'use strict';

import Backbone from 'backbone';

export default Backbone.Model.extend({

  constructor: function (attributes, options) {
    this.on('change', function () {
      let answers = this.get('answers');
      let def = this.get('valueToRestore'), multipleDefaults = [];
      if (!def) {
        def = this.get('defaultValue');
      }

      if (this.get('type') == 11) {
        multipleDefaults = def.split('|');
        if (this.get('minValue') == 0) {
          this.set({required: false}, {silent: true});
        }
      }

      if (answers.length > 0) {
        for (let i in answers) {
          // Try to get rid of HTML that might be part of the answer
          let option = answers[i].text.replace(/(<([^>]+)>)/ig, '');

          if (this.get('type') == 11) {
            answers[i].checked = false;
            for (let j = 0; j < multipleDefaults.length; j += 2) {
              if (multipleDefaults[j] == answers[i].id) {
                answers[i].checked = true;
              }
            }
          }
          else {
            answers[i].checked = (option == def);
          }
        }
        this.set({answers: answers}, {silent: true});
      }

      if (this.get('type') == 10) {
        // Date field
        this.set({required: true}, {silent: true});
        if (this.get('valueToRestore')) {
          this.set({valueToRestore: this.transformDateValueToIso8601(this.get('valueToRestore'))}, {silent: true});
        }
        if (this.get('defaultValue')) {
          this.set({defaultValue: this.transformDateValueToIso8601(this.get('defaultValue'))}, {silent: true});
        }
      }
    });

    Backbone.Model.apply(this, arguments);
  },

  transformDateValueToIso8601: function (date) {
    let reg = date.match(/([0-9]{4})([0-9]{2})([0-9]{2})/);
    if (reg) {
      return reg[1] + '-' + reg[2] + '-' + reg[3];
    }
    return '';
  },

  defaults: {
    'decimals': 0,
    'defaultValue': '',
    'horizontalOrientation': false,
    'id' : '',
    'includeMultimedia': false,
    'isFirst' : false,
    'maxLength': 0,
    'maxText': '',
    'maxValue': 0,
    'minText': '',
    'minValue': 0,
    'multiline': false,
    'multimediaUrl': '',
    'presentationText': '',
    'progress': 0,
    'required': false,
    'spokenText': '',
    'text': '',
    'type': 0,
    'valueToRestore': '',
    'answers': []
  }
});
