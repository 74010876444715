'use strict';

import Backbone from 'backbone';

export default Backbone.Model.extend({

  url: function () {
    return 'coach/get_care_variables?userId=' + encodeURIComponent(this.attributes.userId);
  },

  parse: function (response) {
    let values = {
      userId: this.attributes.userId,
      variables: []
    };

    for (let i in response) {
      values.variables.push(response[i]);
    }

    return values;
  },

  defaults: {
    userId: 0,
    variables: []
  },

  getVariableValue: function (variable) {
    for (let i in this.attributes.variables) {
      if (this.attributes.variables.hasOwnProperty(i) && this.attributes.variables[i].name === variable) {
        return this.attributes.variables[i].value;
      }
    }

    return null;
  }

});
