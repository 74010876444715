'use strict';

import Backbone from 'backbone';

import SessionModel from '../models/session';

export default Backbone.Collection.extend({
  model: SessionModel,
  url: function () {
    return 'coach/get_active_sessions?userId=' + encodeURIComponent(this.userId);
  },

  userId: ''
});
