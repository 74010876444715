'use strict';

import ContentView from "./content";
import App from "../app";
import Validator from 'validatorjs';
import _ from "underscore";
import FormMixin from "../utils/formmixin";
import * as Dialogs from "../utils/dialogs";
import Session from "../utils/session";
import Backbone from "backbone";
import jQuery from "jquery";

let ApprovePrivacyPolicyView = ContentView.extend({
  template: 'approve_privacy_policy',
  isPublic: false,

  ui: {
    form: 'form.user-form',
    submit: 'button[type="submit"]',
    logout: '.logout-link'
  },

  events: {
    'click @ui.submit': 'submit',
    'click @ui.logout': 'logout'
  },

  onBeforeRender: function (options) {
    App.currentPageLocked = true;

    this.templateContext = jQuery.extend({
      privacyStatementUrl: App.config.get('privacyStatementUrl')
    }, this.templateContext);
  },
  
  onRender: function () {
    App.refreshHeader({activeTab: 'profile'});
  },

  submit: function (event) {
    event.preventDefault();

    App.isBusy(true);

    let validation = new Validator(this.getFormValues(this.ui.form), {privacy_policy_approved: 'required'});
    validation.setAttributeNames({privacy_policy_approved: 'akkoord'});
    if (validation.passes()) {

      Session.request('POST', 'user/approve_gdpr', {userId: Session.userId})
        .done(function (data, status, xhr) {
          Backbone.fetchCache.clearItem('coach/account_info?userId=' + encodeURIComponent(Session.userId));
          App.currentPageLocked = false;
          App.isBusy(false);
          App.router.navigate('', {trigger: true});
        })
        .fail(function (xhr, status, error) {
          App.isBusy(false);
          Dialogs.alert('Kan het akkoord niet opslaan');
        });
    }
    else {
      Dialogs.alert(this.getFormDisplayableErrors(validation.errors));
      App.isBusy(false);
    }

    return false;
  },

  logout: function () {
    App.currentPageLocked = false;
  }
});

_.extend(ApprovePrivacyPolicyView.prototype, FormMixin);

export default ApprovePrivacyPolicyView;