'use strict';

import ContentView from '../views/content';

import App from '../app';
import jQuery from 'jquery';

export default ContentView.extend({
  template: 'themes',
  isPublic: false,

  onAttach: function (options) {
    this.listenTo(this.model, 'sync', this.update);
    this.model.fetch({cache: true, expires: 60});
  },

  onRender: function () {
    App.refreshHeader({activeTab: 'themes'});
  },

  update: function (options) {
    this.templateContext = jQuery.extend({}, this.templateContext, this.getTemplateVarsFromModel());
    this.render();
  },

  getTemplateVarsFromModel: function () {
    let currentThemeId = this.model.getVariableValue('ACL_GekozenThema');
    let values = {
      sections: []
    };

    for (let i in App.config.get('themeCategories')) {
      let themes = [];
      // Find variables for this category
      for (let k in App.config.get('themes')) {
        for (let j in this.model.get('variables')) {
          let variable = this.model.get('variables')[j];
          if (variable.name === App.config.get('themes')[k].variableName && variable.value === i) {
            themes.push({
              id: variable.name,
              title: App.config.get('themes')[k].title,
              icon: App.config.get('themes')[k].icon,
              isImprovement: i === 'Ruimte voor verbetering',
              isActive: currentThemeId !== null && currentThemeId === App.config.get('themes')[k].value
            });
          }
        }
      }

      if (themes.length > 0) {
        values.sections.push({
          title: App.config.get('themeCategories')[i].title,
          isHoldOn: i === 'Volhouden',
          themes: themes
        });
      }
    }

    return values;
  }

});
