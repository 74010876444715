'use strict';

import ContentView from '../views/content';

import App from '../app';
import jQuery from 'jquery';

export default ContentView.extend({
  template: 'short_libra_score',
  isPublic: false,
  kioskTimer: null,

  onAttach: function (options) {
    this.listenTo(this.model, 'sync', this.update);
    this.model.fetch({cache: true, expires: 60});
    if (App.kioskMode) {
      this.kioskTimer = window.setTimeout(function () {
        App.router.navigate('#home', {trigger: true});
      }, 120000);
    }
  },

  onDetach: function (options) {
    if (this.kioskTimer) {
      window.clearTimeout(this.kioskTimer);
    }
  },

  onRender: function () {
    App.refreshHeader({showHeader: false});
  },

  update: function (options) {
    this.templateContext = jQuery.extend({}, this.templateContext, this.getTemplateVarsFromModel());
    this.render();
  },

  getTemplateVarsFromModel: function () {
    let values = {
      scores: [],
      hasImprovements: false,
      improvements: [],
      kioskMode: App.kioskMode
    };

    let themes = App.config.get('themes');
    for (let i in themes) {
      let score = {
        themeNr: parseInt(i, 10) + 1,
        themeClass: themes[i].class,
        themeIcon: themes[i].icon,
        themeTitle: themes[i].title,
        isImprovement: false,
        isHoldOn: false
      };

      // Lookup variable in API result
      for (let j in this.model.get('variables')) {
        let variable = this.model.get('variables')[j];
        if (variable.name === themes[i].variableName) {
          // TODO: verify this condition
          if (variable.value === 'Ruimte voor verbetering') {
            score.isImprovement = true;
            values.improvements.push({
              isSecondToLast: false,
              isNotLast: true,
              title: themes[i].title.toLowerCase()
            });
          } else if (variable.value === 'Volhouden') {
            score.isHoldOn = true;
          }
          break;
        }
      }

      values.scores.push(score);
    }

    values.hasImprovements = (values.improvements.length > 0);
    if (values.hasImprovements) {
      values.improvements = values.improvements.map(function (currentValue, index, array) {
        currentValue.isSecondToLast = (index === array.length - 2);
        currentValue.isNotLast = (index !== array.length - 1);
        if (currentValue.isSecondToLast) {
          currentValue.isNotLast = false;
        }
        return currentValue;
      });
    }

    return values;
  }

});
