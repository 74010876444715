'use strict';

import Backbone from 'backbone';

export default Backbone.Model.extend({
  url: function () {
    return 'content/get_content/' + encodeURIComponent(this.attributes.id);
  },

  defaults: {
    id: '',
    intro: '',
    title: '',
    content: ''
  }
});
