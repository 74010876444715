'use strict';

export default {
  getFormValues: function (form) {
    let arr = form.serializeArray();
    let values = {};

    for (let i in arr) {
      values[arr[i].name] = arr[i].value;
    }

    return values;
  },

  getFormDisplayableErrors: function (errors) {
    let str = 'Het formulier bevat de volgende fouten:\n';
    let errorList = errors.all();
    for (let i in errorList) {
      for (let j in errorList[i]) {
        str += '- ' + errorList[i][j] + '\n';
      }
    }

    return str;
  }
};
