'use strict';

import Backbone from 'backbone';

export default Backbone.Model.extend({
  url: function () {
    return 'app/config';
  },

  defaults: {
    themeCategories: [],
    totalRewardCount: 0,
    themes: [],
    privacyStatementUrl: ''
  },

  getThemeByName: function (name) {
    for (let i in this.attributes.themes) {
      if (this.attributes.themes[i].variableName === name) {
        return this.attributes.themes[i];
      }
    }
    return null;
  },

  getThemeById: function (id) {
    for (let i in this.attributes.themes) {
      if (this.attributes.themes[i].id === id) {
        return this.attributes.themes[i];
      }
    }
    return null;
  },

  getThemeByValue: function (value) {
    for (let i in this.attributes.themes) {
      if (this.attributes.themes[i].value === value) {
        return this.attributes.themes[i];
      }
    }
    return null;
  }
});