'use strict';

import Backbone from 'backbone';

export default Backbone.Model.extend({

  defaults: {
    allowBackBrowsing: true,
    id: '',
    title: '',
    trajectTitle: '',
    type: 0,
    isDailySession: false
  }

});
