'use strict';

import Handlebars from 'handlebars';


// Formats a human readable file size from an integer.
Handlebars.registerHelper('filesize', function(object) {
  let size = object;
  let indicator = 'bytes';

  if (size >= 1024 * 1024 * 1024) {
    size = (size / (1024 * 1024 * 1024)).toFixed(2);
    indicator = 'Gb';
  } else if (size >= 1024 * 1024) {
    size = (size / (1024 * 1024)).toFixed(2);
    indicator = 'Mb';
  } else if (size >= 1024) {
    size = Math.round(size / 1024);
    indicator = 'Kb';
  }

  return new Handlebars.SafeString(size + ' ' + indicator);
});


Handlebars.registerHelper('getbykey', function(obj, findKey, subKey, options) {
  for (let key in obj) {
    if (obj[key].key === findKey) {
      return new Handlebars.SafeString(obj[key][subKey]);
    }
  }
  return '';
});


Handlebars.registerHelper('bykey', function(obj, findKey, options) {
  for (let key in obj) {
    if (obj[key].key === findKey) {
      return obj[key];
    }
  }
  return undefined;
});


// Formats a username from user model.
Handlebars.registerHelper('username', function(object) {
  return new Handlebars.SafeString(object.first_name + ' ' + object.last_name);
});

// Formats a username from user model.
Handlebars.registerHelper('patientname', function(object) {
  return new Handlebars.SafeString(object.patient_first_name + ' ' + object.patient_last_name);
});

Handlebars.registerHelper('username_relation', function(object) {
  return new Handlebars.SafeString(object.first_name + ' &amp; ' + object.patient_first_name);
});



Handlebars.registerHelper('ifeq', function(v1, v2, options) {
  if (v1 === v2) {
    return options.fn(this);
  }
  return options.inverse(this);
});

Handlebars.registerHelper('ifne', function(v1, v2, options) {
  if (v1 !== v2) {
    return options.fn(this);
  }
  return options.inverse(this);
});

Handlebars.registerHelper('len', function(object) {
  return object.length;
});

Handlebars.registerHelper({
  eq: function (v1, v2) {
    return v1 === v2;
  },
  ne: function (v1, v2) {
    return v1 !== v2;
  },
  lt: function (v1, v2) {
    return v1 < v2;
  },
  gt: function (v1, v2) {
    return v1 > v2;
  },
  lte: function (v1, v2) {
    return v1 <= v2;
  },
  gte: function (v1, v2) {
    return v1 >= v2;
  },
  and: function (v1, v2) {
    return v1 && v2;
  },
  or: function (v1, v2) {
    return v1 || v2;
  }
});

Handlebars.registerHelper('coach_question_body', function (text) {
  if (this.includeMultimedia && this.multimediaUrl) {
    let mediaEmbed = '';
    if (this.multimediaUrl.indexOf('youtube.com') !== -1) {
      // YouTube embed
      mediaEmbed = '<div class="responsive-embed"><iframe width="560" height="315" src="' + Handlebars.escapeExpression(this.multimediaUrl) + '" frameborder="0" allowfullscreen></iframe></div>';
    } else {
      // Assume it's an image
      mediaEmbed = '<img src="' + Handlebars.escapeExpression(this.multimediaUrl) + '" class="inline-image">';
    }
    text = text.replace(/<mm\/>/i, mediaEmbed);
  }

  return text;
});

Handlebars.registerHelper('format_plural', function (count, singular, plural) {
  if (count == 1) {
    return singular;
  } else {
    return plural.replace('@count', count);
  }
});