'use strict';

import ContentView from '../views/content';

import CoachQuestionModel from '../models/coach_question';
import CareVariablesModel from "../models/care_variables";

import App from '../app';

import Session from '../utils/session';
import * as Dialogs from '../utils/dialogs';

import jQuery from 'jquery';
import Backbone from "backbone";

let Validator = require('validatorjs');

let CoachQuestionView = ContentView.extend({
  template: 'coach_question',
  isPublic: false,

  careVariablesModel: null,

  ui: {
    prevSubmit: '.controls .btn-prev.form-submit',
    nextSubmit: '.controls .btn-next.form-submit',
    cancel: '.cancel a',
    message: '.message',
    rangeQuestions: '.answers input[type="range"]',
    radioButtons: '.answers input[type="radio"]',
    checkBoxes: '.answers input[type="checkbox"]'
  },

  events: {
    'click @ui.prevSubmit': 'previousSubmit',
    'click @ui.nextSubmit': 'nextSubmit',
    'click @ui.cancel': 'cancel',
    'change @ui.radioButtons': 'selectRadioButton',
    'change @ui.checkBoxes': 'selectCheckbox'
  },

  initialize: function (options) {
    this.careVariablesModel = new CareVariablesModel({userId: Session.userId});
    this.listenTo(this.careVariablesModel, 'sync', this.render);
    this.careVariablesModel.fetch({cache: true, expires: 60});
  },

  previousSubmit: function (event) {
    event.preventDefault();

    if (!this.model.get('isFirst')) {
      let view = this;
      App.isBusy(true);
      Session.request('POST', 'contentplayer/get_previous_question', {'userId': Session.userId, 'sessionId': this.getOption('sessionId')})
        .done(function (data, status, xhr) {
          if (data.sessionEnded || !data.question) {
            if (Session.isAnonymous) {
              App.isBusy(false);
              App.router.navigate('#short-libra-score', {trigger: true});
            } else {
              App.isBusy(false);
              App.router.navigate('#snippet', {trigger: true});
            }
            return;
          }

          let model = new CoachQuestionModel(data.question);

          App.isBusy(false);
          App.router.navigate('session/' + view.getOption('sessionId') + '/' + data.question.id);
          App.goto(new CoachQuestionView({
            model: model,
            sessionId: view.getOption('sessionId'),
            dailySession: view.getOption('dailySession')
          }), true);
        })
        .fail(function (xhr, status, error) {
          Dialogs.alert('Er is een fout opgetreden bij het navigeren.');
          App.isBusy(false);
        });
    }

    return false;
  },

  nextSubmit: function (event) {
    event.preventDefault();

    let answer = this.getSubmittedAnswerValue();
    if (answer.error) {
      Dialogs.alert(answer.error);
      return false;
    }

    let view = this;
    let questionData = {
      'userId': Session.userId,
      'sessionId': this.getOption('sessionId'),
      'answer': answer
    };

    App.isBusy(true);
    Session.request('POST', 'contentplayer/get_next_question', questionData)
      .done(function (data, status, xhr) {
        if (!data) {
          Dialogs.alert('Er is een fout opgetreden bij het versturen van het antwoord.');
          App.isBusy(false);
          return;
        }

        if (data.sessionEnded || !data.question) {
          if (Session.isAnonymous) {
            Backbone.fetchCache.clearItem('coach/get_care_variables?userId=' + encodeURIComponent(Session.userId));
            App.isBusy(false);
            App.router.navigate('#short-libra-score', {trigger: true});
          } else {
            Backbone.fetchCache.clearItem('coach/get_active_sessions?userId=' + encodeURIComponent(Session.userId));
            Backbone.fetchCache.clearItem('coach/get_care_variables?userId=' + encodeURIComponent(Session.userId));
            App.isBusy(false);
            App.router.navigate('#snippet', {trigger: true});
          }
          return;
        }

        let model = new CoachQuestionModel(data.question);

        App.isBusy(false);
        App.router.navigate('session/' + view.getOption('sessionId') + '/' + data.question.id);
        App.goto(new CoachQuestionView({
          model: model,
          sessionId: view.getOption('sessionId'),
          dailySession: view.getOption('dailySession')
        }), false);
      })
      .fail(function (xhr, status, error) {
        Dialogs.alert('Er is een fout opgetreden bij het versturen van het antwoord.');
        App.isBusy(false);
      });

    return false;
  },

  cancel: function (event) {
    event.preventDefault();

    if (Session.isLoggedIn && !Session.isAnonymous) {
      App.router.navigate('#snippet', {trigger: true});
    } else {
      App.router.navigate('', {trigger: true});
    }

    return false;
  },

  onBeforeRender: function (options) {
    let currentTheme = '';
    let themeValue = this.careVariablesModel.getVariableValue('ACL_GekozenThema');
    if (themeValue) {
      let theme = App.config.getThemeByValue(themeValue);
      if (theme) {
        currentTheme = theme.title;
      }
    }

    this.templateContext = jQuery.extend({}, this.templateContext, {
      loggedIn: Session.isLoggedIn && !Session.isAnonymous,
      isDailySession: this.getOption('dailySession'),
      currentTheme: currentTheme
    });
  },

  onRender: function (options) {
    if (Session.isAnonymous) {
      App.refreshHeader({showHeader: false});
    }

    let checkedRadios = this.ui.radioButtons.filter(':checked');
    if (checkedRadios.length > 0) {
      this.ui.radioButtons.not(checkedRadios).addClass('unselected');
    }

    if (this.model.get('isFirst')) {
      this.ui.prevSubmit.prop('disabled', true);
    } else {
      this.ui.prevSubmit.prop('disabled', false);
    }

    if (this.model.get('required') && (this.ui.radioButtons.length > 0 || this.ui.checkBoxes.length > 0)) {
      if (checkedRadios.length > 0 || this.ui.checkBoxes.is(':checked')) {
        this.ui.nextSubmit.prop('disabled', false);
      } else {
        this.ui.nextSubmit.prop('disabled', true);
      }
    } else {
      this.ui.nextSubmit.prop('disabled', false);
    }
  },

  onAttach: function () {
    this.ui.rangeQuestions.rangeslider({
      rangeClass: 'rangeslider',
      polyfill: false
    });

    this.ui.message.fitVids();
  },

  selectRadioButton: function (event) {
    jQuery(':radio[name=' + event.currentTarget.name + ']').addClass('unselected');
    jQuery(event.currentTarget).removeClass('unselected');
    this.ui.nextSubmit.prop('disabled', false);
  },

  selectCheckbox: function (event) {
    if (this.model.get('required')) {
      this.ui.nextSubmit.prop('disabled', !this.ui.checkBoxes.is(':checked'));
    }
  },

  getSubmittedAnswerValue: function () {
    let answer = {
      error: null
    };
    let formData, rules, validation;

    switch (this.model.get('type')) {
      case 1: // Message
        answer.questionId = this.model.get('id');
        break;

      case 2: // Multiple Choice
        formData = {answer: this.$el.find('[name="answer"]:checked').val()};
        if (this.model.get('required')) {
          rules = {answer: 'required'};
          validation = new Validator(formData, rules);
          validation.setAttributeNames({answer: 'antwoord'});
          if (validation.passes()) {
            answer.answerId = formData.answer;
            answer.answer = this.$el.find('label[for="answer-' + answer.answerId + '"]').text();
            answer.questionId = this.model.get('id');
          } else {
            answer.error = validation.errors.first('answer');
          }
        } else {
          answer.answerId = formData.answer;
          answer.answer = this.$el.find('label[for="answer-' + answer.answerId + '"]').text();
          answer.questionId = this.model.get('id');
        }

        break;

      case 3: // Number
      case 4: // Slider
      case 5: // Scale
        formData = {answer: this.$el.find('[name="answer"]').val()};
        rules = {answer: 'numeric'};

        if (this.model.get('required')) {
          rules.answer += '|required';
        }
        if (this.model.get('minValue')) {
          rules.answer += '|min:' + this.model.get('minValue');
        }
        if (this.model.get('maxValue')) {
          rules.answer += '|max:' + this.model.get('maxValue');
        }

        validation = new Validator(formData, rules);
        validation.setAttributeNames({answer: 'antwoord'});
        if (validation.passes()) {
          answer.answer = formData.answer;
          answer.questionId = this.model.get('id');
        } else {
          answer.error = validation.errors.first('answer');
        }

        break;

      case 6: // Text
        formData = {answer: this.$el.find('[name="answer"]').val()};
        rules = {answer: []};

        if (this.model.get('required')) {
          rules.answer.push('required');
        }
        if (this.model.get('maxLength')) {
          rules.answer.push('max:' + this.model.get('maxLength'));
        }

        if (rules.answer.length > 0) {
          validation = new Validator(formData, rules);
          validation.setAttributeNames({answer: 'antwoord'});
          if (validation.passes()) {
            answer.answer = formData.answer;
            answer.questionId = this.model.get('id');
          } else {
            answer.error = validation.errors.first('answer');
          }
        } else {
          answer.answer = formData.answer;
          answer.questionId = this.model.get('id');
        }

        break;

      case 10: // Date
        formData = {answer: this.transformDateFormatToApi(this.$el.find('[name="answer"]').val())};
        rules = {answer: []};

        if (this.model.get('required')) {
          rules.answer.push('required');
        }

        if (rules.answer.length > 0) {
          validation = new Validator(formData, rules);
          validation.setAttributeNames({answer: 'antwoord'});
          if (validation.passes()) {
            answer.answer = formData.answer;
            answer.questionId = this.model.get('id');
          } else {
            answer.error = validation.errors.first('answer');
          }
        } else {
          answer.answer = formData.answer;
          answer.questionId = this.model.get('id');
        }

        break;

      case 11: // Multi Select
        let answerIds = [];
        let answerTexts = [];
        let view = this;
        this.$el.find('[name="answer"]:checked').each(function () {
          let answerId = jQuery(this).val();
          answerIds.push(answerId);
          answerTexts.push(answerId + '|' + view.$el.find('label[for="answer-' + answerId + '"]').text());
        });
        formData = {answer: answerIds.join('|')};
        rules = {answer: []};

        if (this.model.get('minValue')) {
          rules.answer.push('min_selected:' + this.model.get('minValue'));
        }
        if (this.model.get('maxValue')) {
          rules.answer.push('max_selected:' + this.model.get('maxValue'));
        }

        if (rules.answer.length > 0) {
          validation = new Validator(formData, rules);
          validation.setAttributeNames({answer: 'antwoord'});
          if (validation.passes()) {
            answer.answer = answerTexts.join('|');
            answer.questionId = this.model.get('id');
          } else {
            answer.error = validation.errors.first('answer');
          }
        } else {
          answer.answer = answerTexts.join('|');
          answer.questionId = this.model.get('id');
        }

        break;
    }

    return answer;
  },

  transformDateFormatToApi: function (date) {
    if (date) {
      // We get the date in ISO-8601 format, convert to YYYYMMDD
      let reg = date.match(/([0-9]{4})-([0-9]{2})-([0-9]{2})/);
      if (reg) {
        return reg[1] + reg[2] + reg[3];
      }
    }

    return null;
  }

});

export default CoachQuestionView;
