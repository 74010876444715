'use strict';

import Session from '../utils/session';
import * as Dialogs from '../utils/dialogs';

export default class {
  init() {
    this._listeners = {};
    this._controller = undefined;
    this._token = undefined;

    if (window.PushNotification) {
      this._push = window.PushNotification.init({
        android: {
          senderID: '392664043145',
          iconColor: '#ad40c0',
          icon: 'notification_icon',
          ledColor: [0, 247, 137, 29],
          clearNotifications: false,
          clearBadge: true,
          forceShow: true
        },
        ios: {
          alert: true,
          badge: true,
          sound: true,
          clearBadge: true
        },
        windows: {}
      });

      this._push.on('registration', this._register.bind(this));
      this._push.on('notification', this._notification.bind(this));
      this._push.on('error', this._error.bind(this));
    }
  }

  setController(controller) {
    this._controller = controller;
  }

  unregister() {
    if (window.PushNotification) {
      Session.request('DELETE', 'push/token?token=' + encodeURIComponent(this.token) + '&tokenType=' + encodeURIComponent(this.tokenType))
        .catch(function (error) {
          console.error('Deleting push notification registration failed: ', error);
        });
      this._push.unregister(function () {}, function () {}, []);
    }
  }

  on(event, func) {
    if (event in this._listeners) {
      this._listeners[event].push(func);
    } else {
      this._listeners[event] = [func];
    }
  }

  off(event, func) {
    if (!(event in this._listeners)) {
      return;
    }

    let eventListeners = this._listeners[event];
    for (let index in eventListeners) {
      if (eventListeners[index] === func) {
        eventListeners.splice(index, 1);
        break;
      }
    }
  }

  trigger(event, data) {
    if (!(event in this._listeners)) {
      return;
    }

    let eventListeners = this._listeners[event];
    for (let index in eventListeners) {
      eventListeners[index](data);
    }
  }

  clearBadge() {
    if (this._push) {
      this._push.setApplicationIconBadgeNumber(function() {}, function() {}, 0);
    }
  }

  setBadge(count) {
    if (this._push) {
      this._push.setApplicationIconBadgeNumber(function() {}, function() {}, count);
    }
  }

  get token() {
    return this._token;
  }

  get tokenType () {
    if (window.device.platform === 'Android') {
      return 'gcm';
    } else if (window.device.platform === 'iOS') {
      return 'apns';
    }

    return null;
  }

  _register(response) {
    this._token = response.registrationId;

    console.log('Push register ', response);

    let data;
    let tokenType = this.tokenType;
    if (tokenType !== null) {
      data = {
        token: response.registrationId,
        tokenType: tokenType,
        userId: Session.userId
      };
    } else {
      return;
    }

    Session.request('POST', 'push/token', data)
      .catch(function(error) {
        console.log('Push token registration error: ', error);
      });
  }

  _notification(data) {
    let title = data.title;
    let message = data.message;
    let extraData = data.additionalData;

    // For iOS, manually display notifications while the app is in the foreground.
    if (window.device.platform === 'iOS' && extraData.foreground) {
      Dialogs.confirm(message, ['Negeer', 'Bekijk'], title)
        .then(function(buttonIndex) {
          if (buttonIndex === 1) {
            this._executeNotification(extraData);
          }
        }.bind(this));
    } else {
      this._executeNotification(extraData);
    }
  }

  _executeNotification(extraData) {
    extraData.inlifeEvent = 'new-session'; // support only 1 message type for now.
    this.clearBadge();

    // Trigger event.
    if (extraData.inlifeEvent) {
      this.trigger(extraData.inlifeEvent, extraData);
    }

    // Call controller function.
    if (this._controller && (extraData.inlifeEvent in this._controller)) {
      this._controller[extraData.inlifeEvent](extraData);
    }
  }

  _error(e) {
    console.error('Notification error:', e);
  }
}
