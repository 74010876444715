'use strict';

import Validator from 'validatorjs';
import ValidatorLangNl from './validatorjs/nl';

export default function () {
  // Set Dutch validation messages
  Validator.setMessages('nl', ValidatorLangNl);
  Validator.useLang('nl');

  // Register custom form validators

  Validator.register('min_digits', function (value, requirement, attribute) {
    let digitsFound = 0;
    let ch;
    for (let i = 0; i < value.length; i++) {
      ch = value.charAt(i);
      if (ch >= '0' && ch <= '9') {
        digitsFound++;
      }
    }
    return digitsFound >= parseInt(requirement, 10);
  }, 'Het :attribute veld moet minimaal :min_digits cijfer(s) bevatten.');

  Validator.register('min_lowers', function (value, requirement, attribute) {
    let lowersFound = 0;
    let ch;
    for (let i = 0; i < value.length; i++) {
      ch = value.charAt(i);
      if (ch >= 'a' && ch <= 'z') {
        lowersFound++;
      }
    }
    return lowersFound >= parseInt(requirement, 10);
  }, 'Het :attribute veld moet minimaal :min_lowers kleine letter(s) bevatten.');

  Validator.register('min_uppers', function (value, requirement, attribute) {
    let uppersFound = 0;
    let ch;
    for (let i = 0; i < value.length; i++) {
      ch = value.charAt(i);
      if (ch >= 'A' && ch <= 'Z') {
        uppersFound++;
      }
    }
    return uppersFound >= parseInt(requirement, 10);
  }, 'Het :attribute veld moet minimaal :min_uppers hoofdletter(s) bevatten');

  Validator.register('min_specialchars', function (value, requirement, attribute) {
    let specialsFound = 0;
    let ch;
    for (let i = 0; i < value.length; i++) {
      ch = value.charAt(i);
      if ((ch >= '!' && ch <= '/') || (ch >= ':' && ch <= '@') || (ch >= '[' && ch <= '`')) {
        specialsFound++;
      }
    }
    return specialsFound >= parseInt(requirement, 10);
  }, 'Het :attribute veld moet minimaal :min_specialchars speciale karakter(s) bevatten.');

  Validator.register('min_selected', function (value, requirement, attribute) {
    var values = value.split('|');
    return values.length >= parseInt(requirement, 10);
  }, 'Selecteer minimaal :min_selected opties in het :attribute veld.');

  Validator.register('max_selected', function (value, requirement, attribute) {
    var values = value.split('|');
    return values.length <= parseInt(requirement, 10);
  }, 'Selecteer maximaal :max_selected opties in het :attribute veld.');

  return Validator;
}
