'use strict';

import Backbone from 'backbone';

import InfoMenuItemModel from '../models/info_menu_item';

export default Backbone.Collection.extend({
  model: InfoMenuItemModel,
  url: 'content/get_info_menu'
});
