import app from '../app';


/**
 * Displays a native alert messagebox, or a broser alert box if we are running inside a webbrowser.
 *
 * @param  {String} message    The message to display.
 * @param  {String} buttonName The name of the button, if supported.
 * @param  {String} title      The title of the dialog, if supported.
 *
 * @return {Promise}           A promise that will resolve as soon as the alert is dismissed.
 */
export function alert(message, buttonName='OK', title=app.name) {
  let promise = new Promise(function(resolve, reject) {

    // Native.
    if (window.navigator.notification) {
      window.navigator.notification.confirm(message, function() {
        resolve();
      }, title, [buttonName]);

    // Browser.
    } else {
      window.alert(message);
      resolve();
    }
  });

  return promise;
}


/**
 * Displays a native confirmation dialog, or a browser confirm box if we are running inside a webbrowser.
 *
 * @param  {String}  message     The message to display.
 * @param  {Array}   buttonNames An array of button names to use, if supported.
 * @param  {String}  title       The title of the dialog, if supported.
 *
 * @return {Promise}             A promise that will resolve once a button is clicked. The first argument is the index
 *                               of the button that was pressed.
 */
export function confirm(message, buttonNames=['Annuleer', 'OK'], title=app.name) {
  let promise = new Promise(function(resolve, reject) {

    // Native.
    if (window.navigator.notification) {
      window.navigator.notification.confirm(message, function(button) {
        resolve(button - 1);
      }, title, buttonNames);

    // Browser.
    } else {
      if (window.confirm(message)) {
        resolve(1);
      } else {
        resolve(0);
      }
    }
  });

  return promise;
}
