'use strict';

import ContentView from '../views/content';
import App from "../app";

export default ContentView.extend({
  template: 'home',
  isPublic: true,

  onAttach: function (options) {
    this.listenTo(this.model, 'sync', this.render);
    this.model.fetch({cache: true, expires: 60 * 60});
  },

  onRender: function () {
    App.refreshHeader();
  }

});
