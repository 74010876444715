'use strict';

import ContentView from '../views/content';

import _ from 'underscore';
import jQuery from 'jquery';
import * as Dialogs from '../utils/dialogs';
import Session from '../utils/session';
import App from '../app';

import BackMixin from '../utils/backmixin.js';
import FormMixin from '../utils/formmixin.js';
import Validator from 'validatorjs';

let ChangePasswordView = ContentView.extend({
  template: 'change_password',
  isPublic: false,

  ui: {
    form: 'form.user-form',
    old_password: '#old_password',
    password: '#password',
    submit: 'button[type="submit"]',
    back: '.back-link'
  },

  events: {
    'click @ui.submit': 'submit',
    'click @ui.back': 'back'
  },

  submit: function (event) {
    event.preventDefault();
    let changePasswordView = this;

    App.isBusy(true);

    // Get password rules
    Session.getPasswordRules().then(function (passwordRules) {
      // Add password validation rules
      let formValidationRules = changePasswordView.getFormValidationRules(passwordRules);

      // Validate form
      let validation = new Validator(changePasswordView.getFormValues(changePasswordView.ui.form), formValidationRules);
      validation.setAttributeNames(changePasswordView.formValidationAttributeNames);
      if (validation.passes()) {
        // Submit password change
        let passwordData = {
          userId: Session.userId,
          oldPassword: changePasswordView.ui.old_password.val(),
          newPassword: changePasswordView.ui.password.val()
        };

        Session.request('POST', 'user/change_password', passwordData)
          .done(function (data, status, xhr) {
            App.isBusy(false);
            App.router.navigate('', {trigger: true});
          })
          .fail(function (xhr, status, error) {
            App.isBusy(false);
            Dialogs.alert('Dit wachtwoord is niet toegestaan omdat het reeds eerder is gebruikt.');
          });
      } else {
        Dialogs.alert(changePasswordView.getFormDisplayableErrors(validation.errors));
        App.isBusy(false);
      }
    },
    function () {
      Dialogs.alert('Er is een fout opgetreden bij het ophalen van de wachtwoordeisen.');
      App.isBusy(false);
    });

    return false;
  },

  onBeforeRender: function (options) {
    let profile = Session.profile;
    this.templateContext = jQuery.extend({}, this.templateContext, {
      profileUsername: typeof profile.username !== 'undefined' ? profile.username : '',
      profileEmail: typeof profile.email !== 'undefined' ? profile.email : '',
      changeRequired: this.getOption('changeRequired')
    });
  },

  onRender: function () {
    App.refreshHeader({activeTab: 'profile'});
  },

  getFormValidationRules: function (passwordRules) {
    let rules = {
      old_password: 'required|different:password',
      password: ['required']
    };

    if (passwordRules.minLength) {
      rules.password.push('min:' + passwordRules.minLength);
    }

    if (passwordRules.minDigits) {
      rules.password.push('min_digits:' + passwordRules.minDigits);
    }

    if (passwordRules.minLowers) {
      rules.password.push('min_lowers:' + passwordRules.minLowers);
    }

    if (passwordRules.minUppers) {
      rules.password.push('min_uppers:' + passwordRules.minUppers);
    }

    if (passwordRules.minSpecialChars) {
      rules.password.push('min_specialchars:' + passwordRules.minSpecialChars);
    }

    rules.password.push('confirmed');

    return rules;
  },

  formValidationAttributeNames: {
    old_password: 'oude wachtwoord',
    password: 'wachtwoord'
  }
});

_.extend(ChangePasswordView.prototype, FormMixin);
_.extend(ChangePasswordView.prototype, BackMixin);

export default ChangePasswordView;
