'use strict';

export default {
  months: [
    'Januari',
    'Februari',
    'Maart',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Augustus',
    'September',
    'Oktober',
    'November',
    'December'
  ],

  getFullMonth: function (date) {
    return this.months[date.getMonth()];
  }
};
