'use strict';

import jQuery from 'jquery';

import './utils/templatecache';
import './utils/handlebarshelpers';

import * as Dialogs from './utils/dialogs';

import app from './app';

window.jQuery = jQuery;

jQuery(window).on('load', function () {
  if (window.cordova) {
    document.addEventListener('deviceready', function () {
      if (window.navigator.connection.type === Connection.NONE) {
        Dialogs.alert('Voor deze app is een internetverbinding vereist.')
          .then(function () {
            window.navigator.app.exitApp();
          });
      } else {
        app.start();
      }
    }, false);
  } else {
    app.start();
  }
});
